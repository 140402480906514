<template>
  <v-row class="customer-detail custom-border-to">
    <v-col md="12" sm="12" class="pt-0">
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="table-tbody"
      >
      </v-skeleton-loader>
      <v-simple-table v-else class="simple-table">
        <template v-slot:default>
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <td style="width: 60px !important">
                <v-checkbox
                  v-model="selectedAll"
                  class="hide-margin py-0 my-0"
                  color="cyan"
                  dense
                  hide-details
                ></v-checkbox>
              </td>
              <td
                style="width: 60px !important"
                class="font-size-13 text-uppercase font-weight-500"
              >
                Status
                <!-- Active / In-Active -->
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">Name</td>
              <!--  <td class="font-size-15 font-weight-600">Last Name</td> -->
              <td class="font-size-13 text-uppercase font-weight-500">
                Display Name
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Email Address
              </td>

              <td class="font-size-13 text-uppercase font-weight-500">
                Mobile Number
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                <template v-if="customer.company_type == 'company'"
                  >DID</template
                >
                <template v-if="customer.company_type == 'personal'"
                  >Landline Number</template
                >
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Designation
              </td>
              <!--   <td class="font-size-13 text-uppercase font-weight-500">
                Department
              </td> -->
              <!-- <td class="font-size-15 font-weight-600">Default</td>
              <td class="font-size-15 font-weight-600">Status</td> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(person, index) in rowData"
              :key="index"
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
            >
              <td style="width: 60px !important">
                <v-checkbox
                  v-model="selectedRows"
                  class="hide-margin"
                  color="cyan"
                  dense
                  hide-details
                  v-bind:value="person.id"
                ></v-checkbox>
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                <div
                  style="width: 60px !important"
                  v-on:click.prevent.stop="activeInactive(person)"
                >
                  <v-switch
                    :disabled="person.default == 1 || customer.activated == 0"
                    v-model="person.activated"
                    class="mt-0"
                    inset
                    v-on:click.prevent.stop="activeInactive(person)"
                    color="green"
                  ></v-switch>
                </div>
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500 text-capitalize"
              >
                {{ person.title }}. {{ person.first_name }}
                <template v-if="person && person.last_name">
                  {{ person.last_name }}
                </template>
                <div>
                  <v-chip
                    small
                    color="blue white--text"
                    label
                    class=""
                    v-if="person.default == 1"
                  >
                    <span class="font-size-16 font-weight-500"> Primary </span>
                  </v-chip>
                </div>
              </td>
              <!--  <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                {{ person.first_name }}
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                {{ person.last_name }}
              </td> -->
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                <template v-if="person && person.display_name">
                  {{ person.display_name }}
                </template>
                <em v-else class="text-muted"> no display name </em>
              </td>

              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500 text-lowercase"
              >
                <template v-if="person && person.primary_email">
                  {{ person.primary_email }}
                </template>
                <em v-else class="text-muted"> no email address </em>
              </td>

              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                <template v-if="person && person.primary_phone">
                  {{ person.primary_phone }}
                </template>
                <em v-else class="text-muted"> no mobile number</em>
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                <template v-if="person && person.did">
                  {{ person.did }}
                </template>
                <em v-else class="text-muted">
                  no
                  {{ customer?.company_type == "company" ? "did" : "landline" }}
                </em>
              </td>
              <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                <template v-if="person && person.designation">
                  {{ person.designation }}
                </template>
                <em v-else class="text-muted"> no designation</em>
              </td>
              <!--  <td
                link
                v-on:click="selectCustomer(person.id)"
                class="font-size-14 font-weight-500"
              >
                <template v-if="person && person.department">
                  {{ person.department }}
                </template>
                <em v-else class="text-muted"> no department</em>
              </td> -->
              <!-- <td class="font-size-14 font-weight-500">
                <Default :status="person.default"></Default>
              </td>
              <td class="font-size-14 font-weight-500">
                <Status :status="person.activated"></Status>
              </td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
// import Status from "@/view/pages/partials/Status.vue";
// import Default from "@/view/pages/partials/Default.vue";
import { PATCH } from "@/core/services/store/request.module";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "contact-persons",
  title: "Customer",
  mixins: [CommonMixin],
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rowData: [],
      selectedRows: [],
    };
  },
  components: {
    // Status,
    // Default,
  },
  watch: {
    selectedRows(param) {
      this.$emit("selectedRows", param);
    },
    customer: {
      deep: true,
      immediate: true,
      handler() {
        this.assignData();
      },
    },
  },
  methods: {
    selectCustomer(param) {
      this.$emit("selectCustomer", param);
    },
    assignData() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customer.persons) === false) {
        _this.rowData = _this.customer.persons;
      }
    },
    activeInactive(data) {
      if (_this?.customer?.activated == 0) {
        return false;
      }
      if (data.default == 1) {
        ErrorEventBus.$emit(
          "update:error",
          "The Primary Contact Person can’t be In-Active."
        );
        return false;
      }

      const _this = this;
      if (data.activated == false) {
        data.activated = 0;
      } else {
        data.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-person/${data.activated}/${data.id}`,
          data: {
            id: data.id,
            status: data.activated,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {});
    },
  },
  mounted() {
    const _this = this;
    _this.assignData();
    _this.$root.$on("clearSelection", () => {
      _this.selectedRows = [];
    });
  },
  beforeDestroy() {
    /*this.$root.$off("clearSelection");*/
  },
  computed: {
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
};
</script>
